<script lang="ts" setup>
import type { INote } from '@register'

interface Props {
  note: INote
}
defineProps<Props>()
const emit = defineEmits<{
  delete: []
}>()
const { getField } = useCurrentLeaseDocument()
</script>

<template>
  <section :id="note.id" class="mt-4 rounded-sm p-2 transition-all">
    <div class="flex items-center justify-between">
      <div class="mb-1 mt-2 flex w-full items-center gap-2 font-semibold">
        <UserPicture :name="note.user?.name" :email="note.user?.email" />

        <span class="flex grow flex-col">
          <span class="">{{ note.user?.name || note.user?.email }}</span>
          <time class="text-xs text-gray-500">
            {{ $dayjs(note.createdAt).format('DD MMM YYYY, HH:mm') }}
          </time>

          <span v-if="note.subEntityName" class="flex gap-1 text-xs">
            <span class="text-xs text-gray-500">Field:</span>
            <span class="text-primary">
              {{
                getField(note.subEntityName)?.name ?? title(note.subEntityName)
              }}
            </span>
          </span>
        </span>
      </div>

      <ActionMenu>
        <ActionMenuItem @click="emit('delete')">Delete</ActionMenuItem>
      </ActionMenu>
    </div>

    <article class="w-full whitespace-break-spaces break-all">
      {{ note.message }}
    </article>
  </section>
</template>
