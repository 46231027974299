import type { IEntity } from './types'
import type { INote } from '@register'

export const useNotes = (
  entity: IEntity,
  entityId: string,
  init?: string[],
) => {
  const { getNotes, createNote, deleteNote } = useApiNotes(entity, entityId)

  const originalNotes = computed(() => getNotes.data?.value ?? [])
  const notes = ref<INote[]>(originalNotes.value)

  // current user
  const { data: user } = useAuth()
  // unique notes fields for filter
  const uniqueSubEntities = computed(() => {
    const notesWithField = originalNotes.value
      .filter((note) => note.subEntityName)
      .map((note) => note.subEntityName)
    return unique(notesWithField) as string[]
  })
  // notes filter
  const filterModel = reactive({
    myNotes: false,
    filters: [] as string[],
  })

  if (init?.length) {
    filterModel.filters = init
  }

  watchEffect(() => {
    notes.value = originalNotes.value.filter((note) => {
      if (filterModel.myNotes && note.user?.id !== user.value?.id) {
        return false
      }

      if (
        filterModel.filters.length &&
        !filterModel.filters.includes(note.subEntityName!)
      ) {
        return false
      }

      return true
    })
  })

  return {
    notes,
    filterModel,
    uniqueSubEntities,
    createNote,
    deleteNote,
  }
}
