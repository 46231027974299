/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Entity {
    LEASE = 'lease',
    PROPERTY_LEASE = 'propertyLease',
    PROPERTY = 'property',
    PROPERTY_UNIT = 'propertyUnit',
    AGENT = 'agent',
    LESSOR = 'lessor',
    LESSEE = 'lessee',
    FRANCHISEE = 'franchisee',
    FINANCIAL_LEASE = 'financialLease',
    LEASE_BANK_DETAIL = 'leaseBankDetail',
    FINANCIAL_LEASE_REVIEW = 'financialLeaseReview',
    RENT_REVIEW = 'rentReview',
    MARKETING_LEVY_REVIEW = 'marketingLevyReview',
    MARKET_RENT_REVIEW = 'marketRentReview',
    VENDOR = 'vendor',
    PROPERTY_LEASE_INCENTIVE = 'propertyLeaseIncentive',
    MARKETING_ALLOWANCE = 'marketingAllowance',
    ABATEMENT = 'abatement',
    RENT_CONCESSION = 'rentConcession',
    MARKETING_LEVY_CONCESSION = 'marketingLevyConcession',
    OUTGOING_DETAIL = 'outgoingDetail',
    OUTGOING_CATEGORY = 'outgoingCategory',
    PERIOD_OUTGOING = 'periodOutgoing',
    DOCUMENT = 'document',
    USER = 'user',
    COMPANY = 'company',
    COMPANY_CONTACT = 'companyContact',
    COMPANY_ADDRESS = 'companyAddress',
    OPTION = 'option',
    USAGE = 'usage',
    BASE_RENT = 'baseRent',
    TURNOVER_RENT = 'turnoverRent',
    OUTGOINGS = 'outgoings',
    MARKETING_LEVY = 'marketingLevy',
    EMAIL_REMINDER = 'emailReminder',
    SECURITY = 'security',
    ADDRESS = 'address',
    CONTACT = 'contact',
    LEASE_DOCUMENT_FIELD = 'leaseDocumentField',
    PROPERTY_LEASE_OPTION_INFO = 'propertyLeaseOptionInfo',
    LEASE_VARIATION = 'leaseVariation',
    LEASE_SPECIAL_CONDITION = 'leaseSpecialCondition',
}
