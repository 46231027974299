import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { IEntity } from './types'
import type {
  INote,
  ICreateNoteRequest,
  IUpdateNoteRequest,
  ILeaseRegisterLease,
} from '@register'

// GET /countries
export const useApiNotes = (entity: IEntity, entityId: string) => {
  const queryClient = useQueryClient()
  const dayjs = useDayjs()
  const divisionId = getCurrentDivisionId()
  const currentDocument = useCurrentLeaseDocument()

  // GET all notes from an entity
  const getNotes = useQuery<INote[]>({
    queryKey: ['notes', entity, entityId],
    queryFn: () =>
      fetchAuthorized<INote[]>(`/entities/${entity}/${entityId}/notes`).then(
        (res) =>
          res.sort((a, b) =>
            dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1,
          ),
      ),
  })

  const createNote = useMutation({
    mutationFn: (body: ICreateNoteRequest) =>
      fetchAuthorized(`/entities/${entity}/${entityId}/notes`, {
        method: 'POST',
        body,
      }),
    onSuccess: <INote>(note: INote) => {
      try {
        //update the model optimistically
        queryClient.setQueryData(
          ['notes', entity, entityId],
          (oldNotes: INote[] | undefined) => {
            return oldNotes ? [note, ...oldNotes] : [note]
          },
        )

        // Invalidate summary
        queryClient.invalidateQueries({
          queryKey: getApiLeaseSummaryKey(
            divisionId,
            currentDocument.lease.value.id,
          ),
        })

        // Invalidate Lease Document
        queryClient.invalidateQueries({
          queryKey: getLeaseDocumentKey(
            divisionId,
            currentDocument.lease.value.id,
          ),
        })
      } catch (err) {
        console.error(err)
      }
    },
  })

  const updateNote = useMutation({
    mutationFn: (body: IUpdateNoteRequest) =>
      fetchAuthorized(`/entities/${entity}/${entityId}/notes`, {
        method: 'PUT',
        body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', entity, entityId] })
    },
  })

  const deleteNote = useMutation({
    mutationFn: (noteId: string) =>
      fetchAuthorized(`/notes/${noteId}`, {
        method: 'DELETE',
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notes', entity] })
    },
  })

  return {
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  }
}
