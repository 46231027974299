<script lang="ts" setup>
interface Props {
  modelValue: Record<string, unknown>
  subEntityOptions: string[]
  showFilters: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showFilters: false,
  subEntityOptions: () => [],
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: Record<string, unknown>): void
  (e: 'show:filters', value: boolean): void
}>()

const filterModel = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const options = computed(() => {
  return props.subEntityOptions.map((option) => ({
    label: option,
    value: option,
  }))
})
</script>

<template>
  <FormKit
    ref="filterForm"
    v-slot="{ state }"
    v-model="filterModel"
    type="form"
    dirty-behavior="compare"
    :actions="false"
  >
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-[-500px] opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-[-500px] opacity-0"
    >
      <div v-show="showFilters" class="bg-gray-850 mt-4 rounded-lg p-4">
        <p class="mb-4 text-sm font-semibold">Filters:</p>
        <div class="grid grid-cols-2">
          <FormKit
            type="checkbox"
            name="myNotes"
            label="Only my notes"
            outer-class="flex items-center"
          />
        </div>

        <FormKit
          type="listbox"
          :multiple="true"
          name="filters"
          placeholder="Filter by field"
          outer-class="w-full mt-4"
          track-by="value"
          label-by="label"
          hide-selected
          allow-empty
          :options="options"
          :searchable="true"
        />
      </div>
    </transition>
    <div
      v-if="state.dirty && !showFilters"
      class="border-warning bg-warning/5 mt-4 flex justify-between rounded-lg border px-4 py-2 transition-all"
    >
      <span class="self-center">Filtering results</span>
      <Button color="secondary" @click="emit('show:filters', true)">
        Show filters
      </Button>
    </div>
  </FormKit>
</template>
