import { IEntity } from './types'
import { useModal } from 'vue-final-modal'
// @ts-ignore
import NotesModal from '~/components/NotesModal/NotesModal.vue'

export function openNotesModal(
  entity: IEntity,
  entityId: string,
  subEntityName?: string,
  subEntityId?: string,
) {
  const { open: openNotes, close: closeNotes } = useModal({
    component: NotesModal,
    attrs: {
      id: 'modalNotes',
      entity,
      entityId,
      subEntityName,
      subEntityId,
      onBeforeClose: () => {},
      onClosed: () => {
        closeNotes()
      },
      onClose: () => {
        closeNotes()
      },
    },
  })
  openNotes()
}
