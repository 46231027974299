<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'
import { useElementBounding } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'
import { useNotes } from './composables/useNotes'
import type { IEntity, NoteMessage } from './composables/types'
import type { INote } from '@register'
import NewNote from './NewNote.vue'
import { ref } from 'vue'

interface Props {
  entity: IEntity
  entityId: string
  subEntityName?: string
  subEntityId?: string
}
const props = withDefaults(defineProps<Props>(), {
  subEntityName: undefined,
  subEntityId: undefined,
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { getField } = useCurrentLeaseDocument()
const showFilters = ref(!!props.subEntityId)

const { notes, filterModel, uniqueSubEntities, createNote, deleteNote } =
  useNotes(
    props.entity,
    props.entityId,
    props.subEntityId ? [props.subEntityId] : undefined,
  )

// dynamic height for the notes modal
const navHeader = ref(null)
const { height: navHeaderHeight } = useElementBounding(navHeader)

const newComment = ref<HTMLElement & typeof NewNote>(null!)
const { height: newCommentHeight } = useElementBounding(newComment)

watch(
  () => navHeaderHeight.value,
  () => {
    document.documentElement.style.setProperty(
      '--notes-nav-height',
      `${navHeaderHeight.value}px`,
    )
  },
  {
    immediate: true,
  },
)

watch(
  () => newCommentHeight.value,
  () => {
    document.documentElement.style.setProperty(
      '--notes-new-comment-height',
      `${newCommentHeight.value}px`,
    )
  },
  {
    immediate: true,
  },
)

// handlers
function onClose(): void {
  emit('close')
}
const isCreatingNotes = ref(false)

const onCreateNote = useSubmitHandler<NoteMessage>(
  (note) =>
    createNote.mutateAsync({
      subEntityName: props.subEntityId,
      subEntityId: null,
      ...note,
    }),
  {
    toastOptions: {
      position: 'top-left',
    },
    loadingMessage: 'Creating note...',
    successMessage: 'Note created',
    errorMessage: 'Failed to create note',
    beforeSubmit: () => {
      isCreatingNotes.value = true
    },
    onSuccess: (data) => {
      const note = data as INote
      newComment.value?.clearInput()
      scrollToNewNote(note.id!)
    },
    onFinally: () => {
      isCreatingNotes.value = false
    },
  },
)

const onDelete = useConfirmHandler<INote>(
  (note) => deleteNote.mutateAsync(note.id!),
  {
    modal: {
      title: 'Delete note',
      body: 'Are you sure you want to delete this note?',
      submitButton: 'Delete',
    },
    toastOptions: {
      position: 'top-left',
    },
    loadingMessage: 'Deleting note...',
    successMessage: 'Note deleted',
    errorMessage: 'Failed to delete note',
  },
)

function scrollToNewNote(noteId: string) {
  nextTick(() => {
    const div = document.getElementById(noteId)
    if (!div) return
    // scroll to the new note
    const scrollWrapper = document.getElementsByClassName(
      'simplebar-content-wrapper',
    )[0]
    scrollWrapper?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // highlight the new note
    div.classList.add('blink-new-element')
    setTimeout(() => {
      div.classList.remove('blink-new-element')
    }, 1500)
  })
}
</script>

<template>
  <VueFinalModal
    overlay-transition="vfm-fade"
    content-transition="vfm-slide-none"
  >
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-x-[500px] opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-x-0 opacity-100"
      leave-to-class="translate-x-[500px] opacity-0"
    >
      <div
        class="absolute bottom-0 right-0 top-0 h-full w-full bg-white md:w-1/3 dark:bg-gray-800"
        tabindex="0"
      >
        <div
          ref="navHeader"
          class="shaddow absolute top-0 z-10 w-full bg-neutral-100/30 p-4 text-xl backdrop-blur-2xl backdrop-saturate-[180%] dark:bg-black/30"
        >
          <span class="flex justify-between">
            <h1>Notes</h1>

            <span>
              <Button
                color="secondary"
                class="mr-2 h-8 w-8 px-0 py-0"
                icon="filter"
                @click="showFilters = !showFilters"
              >
              </Button>
              <Button
                color="secondary"
                class="h-8 w-8 px-0 py-0"
                icon="close"
                @click="onClose"
              >
              </Button>
            </span>
          </span>
        </div>

        <Scrollbar
          class="absolute left-0 right-0 top-0 h-screen px-4 pb-[var(--notes-new-comment-height)] pt-[var(--notes-nav-height)] transition-all"
        >
          <NotesModalFilter
            v-model="filterModel"
            :show-filters="showFilters"
            :sub-entity-options="uniqueSubEntities"
            @show:filters="(value) => (showFilters = value)"
          />
          <div v-if="notes.length === 0" class="mt-20 text-center">
            <h2>
              There are no notes
              <template v-if="subEntityName">
                for
                <span class="text-primary">
                  {{ getField(subEntityName)?.name ?? subEntityName }}
                </span>
              </template>
              yet.
            </h2>
            <p>add a new one</p>
          </div>
          <TransitionSlide
            v-else
            group
            appear
            class="grid w-full grid-cols-1 gap-4 divide-y divide-neutral-200 dark:divide-gray-700"
          >
            <NotesModalNote
              v-for="note in notes"
              :key="note.id"
              :note="note"
              @delete="onDelete(note)"
            />
          </TransitionSlide>
        </Scrollbar>

        <NotesModalNewNote
          ref="newComment"
          :field-id="subEntityName"
          :is-creating-notes="isCreatingNotes"
          @submit="onCreateNote"
        />
      </div>
    </transition>
  </VueFinalModal>
</template>

<style lang="postcss" scoped>
.overfow-x-scroll {
  @supports (scrollbar-gutter: stable) {
    overflow: auto;
    scrollbar-gutter: stable;
  }
}

:deep(.simplebar-track) {
  @apply z-50 cursor-grab overflow-visible;
}

:deep(.simplebar-vertical) {
  @apply bottom-[var(--notes-new-comment-height)] top-[var(--notes-nav-height)] w-2.5;
}
</style>
